@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@900&display=swap");

* {
  font-family: "nunito";
  font-weight: 900;
}

.h2 {
  font-family: "nunito";
}
.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.divContainer {
  padding-top: 200px;
  padding-bottom: 80px;
}

.containerPrinc {
  padding-top: 80px;
}

.cardBimbi {
  font-family: "nunito";
  border: 3px solid #feb8cc;
  border-style: dashed;
  height: 280px;
}

.btnCard {
  border: none;
  width: 130px;
  font-family: "nunito";
  background-color: #45b3df;
  border-radius: 24px;
  color: white;
  font-weight: 900;
  font-size: 18px;
  outline: 3px solid white;
  outline-style: dashed;
  outline-offset: -8px;
  padding: 10px;
  transition: 0.4s all;
}
.btnCard:hover {
  background-color: #ffc85b;
}
.mapDettaglio {
  font-family: "nunito";
  padding: 5px;
  border: 3px solid #278878;
  background-color: #31ce9a9d;
  border-radius: 14px;
  border-style: dashed;
  margin: 5px;
  font-size: 13px;
  transition: all 0.2s;
}

.mapDettaglio:hover {
  border: 3px solid #ffc85b;
  background-color: #faf1dda4;
  border-radius: 14px;
  border-style: dashed;
}

.btn_Ritorno {
  font-family: "nunito";
  padding: 20px;
  border: 4px solid #feb8cc;
  background-color: #ffe3e9;
  border-radius: 45px;
  border-style: dashed;
  margin: 5px;
  font-size: 25px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: #f5dcaa;
  border-radius: 100vh;
}

::-webkit-scrollbar-thumb {
  background-color: #ffc85b;
  border-radius: 100vw;
  border: 1px solid rgb(250, 165, 7);
}

.logo_kd {
  width: 55px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.btnRicerca {
  width: 100% !important;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
