.containerPrincipale {
  padding-top: 80px;
  user-select: none;
}

.divContainerToten {
  padding-top: 70px;
  padding-bottom: 80px;
}

.totem {
  zoom: 75%;
}

/*::-webkit-scrollbar {
  display: none;
}*/

/* Animazioni */

.square1 {
  margin-left: 150px;
  width: 60px;
  height: 60px;
  background-color: #ed880e;
}

.square2 {
  margin-left: 1150px;
  width: 60px;
  height: 60px;
  margin-top: 50px;
  background-color: #152a61;
}

.square3 {
  width: 90px;
  height: 90px;
  margin-left: 790px;
  margin-top: -50px;
  background-color: #152a61;
}

.square4 {
  width: 65px;
  height: 65px;
  margin-left: 200px;
  background-color: #ffc85b;
}

.square5 {
  width: 40px;
  height: 40px;
  margin-left: 450px;
  margin-top: -50px;
  background-color: yellow;
}

.square6 {
  width: 60px;
  height: 60px;
  margin-left: 1000px;
  margin-top: -50px;
  background-color: #ed880e;
}

.cloud1 {
  margin-left: 800px;
  width: 250px;
  margin-top: -40px;
  z-index: -100;
  position: relative;
}

.cloud2 {
  margin-left: 100px;
  width: 250px;
  z-index: -100 !important;
  position: relative;
}

.slide {
  animation: slide 7s cubic-bezier(0.65, 0.46, 0.45, 0.94) infinite alternate;
}

.slide2 {
  animation: slide2 7s cubic-bezier(0.65, 0.46, 0.45, 0.94) infinite alternate;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100px);
  }
}

@keyframes slide2 {
  0% {
    transform: translateX(-100px);
  }

  100% {
    transform: translateX(0);
  }
}

.ricercaFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
}